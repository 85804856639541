import { css, notDesktop, styled, Text } from "@obvio/app";
import { MapSection, RichText } from "@obvio/template";
import { Grid, Section, Stack } from "@obvio/ui";
import dynamic from "next/dynamic";

import { CarouselSection } from "../CarouselBase";
import { CommonSection } from "../CommonSection";
import { ImagesCarousel } from "../ImagesCarousel";

import type { ReactElement } from "react";

const LazyInstagram = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Instagram" */
    "./Renderers/Instagram"
  );
  return comp.Instagram;
});

const LazyTextFlow = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "TextFlow" */
    "./Renderers/TextFlow"
  );
  return comp.TextFlow;
});

const LazyGalleriesText = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "GalleriesText" */
    "./Renderers/GalleriesText"
  );
  return comp.GalleriesText;
});

const LazyImagesGrid = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "ImagesGrid" */
    "./Renderers/ImagesGrid"
  );
  return comp.ImagesGrid;
});

const LazyTeam = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Team" */
    "./Renderers/Team"
  );
  return comp.Team;
});

const LazyImageImage = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "ImageImage" */
    "./Renderers/ImageImage"
  );
  return comp.ImageImage;
});

const LazyFullSection = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "FullSection" */
    "./Renderers/FullSection"
  );
  return comp.FullSection;
});

const LazyImagesFloat = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "ImagesFloat" */
    "./Renderers/ImagesFloat"
  );
  return comp.ImagesFloat;
});

const LazyHero = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Hero" */
    "./Renderers/Hero"
  );
  return comp.Hero;
});

const LazyMap = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Map" */
    "./Renderers/Map"
  );
  return comp.GolfMap;
});

const LazyRichTextImage = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "RichTextImage" */
    "./Renderers/RichTextImage"
  );
  return comp.RichTextImage;
});

const LazyForm = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Form" */
    "./Renderers/Form"
  );
  return comp.FormContact;
});

const LazyFormNewsletter = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Form" */
    "./Renderers/FormNewsletter"
  );
  return comp.FormNewsletter;
});

const LazyArticlesLatest = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "ArticlesLatest" */
    "./Renderers/ArticlesLatest"
  );
  return comp.ArticlesLatest;
});

const LazyArticlesAll = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "ArticlesAll" */
    "./Renderers/ArticlesAll"
  );
  return comp.ArticlesAll;
});

const MapSectionSpacing = styled(MapSection)`
  margin: 0;
  height: 60vh;
  > div {
    height: 100%;
  }
  @media ${notDesktop} {
    height: 30rem;
  }

  background: ${(theme) => theme.colors.primary};
`;

const HeroSection = styled(Section)`
  padding: 0;

  margin: 0;
  padding: 0 !important;
  max-width: 100vw !important;
  width: 100vw;
  position: relative;
  height: 100vh;
  max-height: 1280px;

  > section {
    height: 100%;
  }
`;

const TextGrid = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`;

const CenterText = styled.div`
  text-align: center;
`;

const HeadingWrap = styled(Stack)`
  align-items: center;
  > * {
    display: inline-block;
  }
  > p {
    max-width: 17rem;
  }
  @media ${notDesktop} {
    flex-direction: column;
    > p {
      max-width: unset;
      margin-left: 0 !important;
    }
  }
`;

const Heading = styled(Text)`
  font-size: min(7.5rem, 10vw);
  text-align: left;
`;

export const customRenderers: (
  pageId?: string,
) => Record<string, (props: any) => ReactElement | null> = (_pageId) => ({
  team: ({ members, __SETTINGS }) => (
    <CommonSection {...__SETTINGS}>
      <LazyTeam members={members} />
    </CommonSection>
  ),
  "galleries-text": ({ title, galeries, description, __SETTINGS }) => (
    <CommonSection {...__SETTINGS}>
      <LazyGalleriesText
        title={title}
        galleries={galeries}
        description={description}
      />
    </CommonSection>
  ),
  heading: ({ text, description, __SETTINGS }) => (
    <CommonSection {...__SETTINGS}>
      <HeadingWrap spacing="large">
        <Heading tag="h2">{text}</Heading>
        {description ? <Text>{description}</Text> : null}
      </HeadingWrap>
    </CommonSection>
  ),
  "full-section": ({
    image,
    text1,
    image2,
    images,
    text2,
    text3,
    __SETTINGS,
  }) => (
    <CommonSection {...__SETTINGS}>
      <LazyFullSection
        image={image}
        text1={text1}
        image2={image2}
        images={images}
        text2={text2}
        text3={text3}
      />
    </CommonSection>
  ),
  "image-image": ({ image, image2, __SETTINGS }) => (
    <CommonSection {...__SETTINGS}>
      <LazyImageImage image={image[0]} image2={image2[0]} />
    </CommonSection>
  ),
  "image-grid": ({ images, __SETTINGS }) => (
    <CommonSection {...__SETTINGS}>
      <LazyImagesGrid images={images} />
    </CommonSection>
  ),
  "image-float": ({ images, __SETTINGS }) => (
    <CommonSection
      {...__SETTINGS}
      outerCss={css`
        width: 100%;
        max-width: unset;
        overflow-x: clip;
        > div {
          max-width: unset;
          width: 100vw;
        }
      `}
    >
      <LazyImagesFloat images={images} />
    </CommonSection>
  ),
  hero: ({ image, text1, text2 }) => (
    <HeroSection context="black">
      <LazyHero image={image[0]} text1={text1} text2={text2} />
    </HeroSection>
  ),
  richText: ({ description, __SETTINGS }) => (
    <CommonSection {...__SETTINGS}>
      <CenterText>
        <RichText value={description} />
      </CenterText>
    </CommonSection>
  ),
  "flow-text": ({ texts, __SETTINGS }) => (
    <CommonSection {...__SETTINGS} noFadeIn>
      <LazyTextFlow texts={texts} />
    </CommonSection>
  ),
  "richText1-richText2": ({ richText1, richText2, __SETTINGS }) => (
    <CommonSection context="white" {...__SETTINGS}>
      <TextGrid templateColumns="1fr 1fr" gap="extraLarge">
        <div>
          <RichText value={richText1} />
        </div>
        <div>
          <RichText value={richText2} />
        </div>
      </TextGrid>
    </CommonSection>
  ),
  "richText-images": ({ description, image, __SETTINGS }) => (
    <CommonSection {...__SETTINGS}>
      <LazyRichTextImage
        description={description}
        image={image?.[0]}
        image2={image?.[1]}
      />
    </CommonSection>
  ),
  form: ({ title, description, description2, description3, __SETTINGS }) => (
    <CommonSection {...__SETTINGS} context="formdark">
      <LazyForm
        title={title}
        description={description}
        description2={description2}
        description3={description3}
      />
    </CommonSection>
  ),
  "form-newsletter": ({ __SETTINGS }) => (
    <CommonSection {...__SETTINGS}>
      <LazyFormNewsletter />
    </CommonSection>
  ),
  "image-carousel": ({ images, __SETTINGS }) => (
    <CarouselSection {...__SETTINGS}>
      <ImagesCarousel images={images} />
    </CarouselSection>
  ),
  "articles-latest": ({ title, limit, __SETTINGS }) => (
    <CommonSection {...__SETTINGS}>
      <LazyArticlesLatest title={title} limit={limit} />
    </CommonSection>
  ),
  "articles-all": ({ __SETTINGS }) => (
    <CommonSection {...__SETTINGS}>
      <LazyArticlesAll />
    </CommonSection>
  ),
  map: ({ __SETTINGS }) => (
    <MapSectionSpacing {...__SETTINGS} id={__SETTINGS?.name}>
      <LazyMap />
    </MapSectionSpacing>
  ),
  instagram: ({ __SETTINGS }) => (
    <CommonSection
      {...__SETTINGS}
      outerCss={css`
        width: 100%;
        max-width: unset;
        overflow-x: clip;
        > div {
          max-width: unset;
          width: 100vw;
        }
      `}
    >
      <LazyInstagram />
    </CommonSection>
  ),
});
